import React from "react";
import { Button, Image } from "antd";
import tinycolor from "tinycolor2";
import { SuccessModalDefaultProps, SuccessModalPropsType } from "./style.types";
import { ModalWrap, ModalDataWrap, CustomTitle } from "./StyledComponent";

function RegistrationModal({
  registrationSuccessMessage,
  buttonTitle,
  btnURL = "",
  preview,
  btnColor,
  titleColor,
}: SuccessModalPropsType) {
  return (
    <ModalWrap className={`${preview === "success" && "success-message"}`}>
      <ModalDataWrap>
        <Image preview={false} style={{ height: "80px" }} src='/images/success-img.svg' />
        <CustomTitle
          mt={8}
          fontWeight={400}
          fontSize={24}
          lineheight={32}
          color='#000000'
          fontFamily='nunito-sans'
        >
          Success! Registration Complete
        </CustomTitle>
        <CustomTitle
          fontWeight={400}
          fontSize={16}
          lineheight={24}
          color='#212529'
          fontFamily='nunito-sans'
          className='success-msg'
        >
          {registrationSuccessMessage}
        </CustomTitle>
      </ModalDataWrap>
      <Button
        onClick={() =>
          btnURL &&
          window.open(
            btnURL.includes("www.") || btnURL.includes("http") ? btnURL : `https://${btnURL}`,
          )
        }
        style={{
          minWidth: "148px",
          paddingLeft: "20px",
          paddingRight: "20px",
          height: "40px",
          backgroundColor: btnColor,
          borderRadius: "4px",
          ...(tinycolor(btnColor).isLight() && { border: "1px solid rgb(211, 211, 211)" }),
          color: `${tinycolor(btnColor).isLight() ? titleColor[1] : titleColor[0]}`,
        }}
      >
        {buttonTitle}
      </Button>
    </ModalWrap>
  );
}

RegistrationModal.defaultProps = SuccessModalDefaultProps;

export default RegistrationModal;
