import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import App from "./App";
import { store } from "./redux/store";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new HttpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [400, 500],
      failedRequestTargets: [/.*/],
    }),
  ],
  tracesSampleRate: 1.0, // lower the value in production
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
export default Sentry.withProfiler(App);
