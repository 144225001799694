import React, { useState } from "react";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import NotFound from "../not-found";
import { useThemeDetailsQuery } from "../redux/slices/register/registerApiSlice";
import { BoredredWrap, MainWrap, Section } from "../StyledComponent";
import RegistrationTheme from "../theme/RegistrationTheme";

function Index() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid") || "";
  const cloud = searchParams.get("cloud") || "";

  const { data, isLoading, isError } = useThemeDetailsQuery(
    {
      uuid,
    },
    {
      skip: !uuid,
    },
  );

  if (uuid && !isError) {
    return (
      <MainWrap>
        <Spin spinning={isLoading}>
          <Section style={{ height: "100vh" }}>
            {!isLoading && (
              <BoredredWrap>
                <RegistrationTheme
                  registrationData={data}
                  successMessage={successMessage}
                  onFinish={() => setSuccessMessage(true)}
                  isLoading={false}
                  cloud={cloud}
                />
              </BoredredWrap>
            )}
          </Section>
          {/* <RegistrationDataFields registrationData={data} /> */}
        </Spin>
      </MainWrap>
    );
  }

  return <NotFound />;
}

export default Index;
