import React from "react";
import { PictureOutlined } from "@ant-design/icons";
import { Row, Col, Image, Typography } from "antd";
import { Section, RegisterThemeLogo, PoweredTag } from "../../StyledComponent";

const { Text } = Typography;

function RegistrationHearder({ logo, hideClazarBranding }: any) {
  return (
    <Section className='registration-header-wrapper'>
      <Section className='registration-header-container'>
        <Row>
          <Col span={24} className='column-registration'>
            {logo ? (
              <Image className='databricks' preview={false} src={logo} />
            ) : (
              <RegisterThemeLogo>
                <PictureOutlined /> <span>Your Logo Here</span>
              </RegisterThemeLogo>
            )}
            <PoweredTag className='mobile-powered-tag'>
              {!hideClazarBranding && (
                <>
                  <Text>Powered by</Text>
                  <Image
                    preview={false}
                    src='/images/logo.png'
                    height={44}
                    style={{ padding: 10 }}
                  />
                </>
              )}
            </PoweredTag>
          </Col>
        </Row>
      </Section>
    </Section>
  );
}

export default RegistrationHearder;
