import React from "react";
import { useSearchParams } from "react-router-dom";
import NotFound from "../not-found";
import CloudRegistration from "./cloud-registration";
import AWSRegistartion from "./aws-registration";

function Index() {
  const [searchParams] = useSearchParams();

  const sellerId = searchParams.get("sellerId") || "";
  const productCode = searchParams.get("productCode") || "";
  const customerIdentifier = searchParams.get("customerIdentifier") || "";
  const cloud = searchParams.get("cloud") || "";
  const token = searchParams.get("token") || "";

  if (cloud === "azure" || cloud === "gcp") {
    return <CloudRegistration token={token} cloud={cloud} />;
  }
  if (sellerId && productCode && customerIdentifier) {
    return (
      <AWSRegistartion
        sellerId={sellerId}
        productCode={productCode}
        customerIdentifier={customerIdentifier}
      />
    );
  }

  return <NotFound />;
}

export default Index;
