// Themes
export const THEMES = {
  DEFAULT: "BLUE", // DEFAULT
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

const localHost = "http://localhost:8000";

const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";
const baseURL = process.env.REACT_APP_BASE_API_URL;

export const BASE_API_URL = isDevelopment || !baseURL ? localHost : baseURL;

export const REACT_APP_GOOGLE_CLIENT_ID = isDevelopment
  ? process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID
  : process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID;

// google client id dev - 595687395228-kg6fhmpa64rf2m980c98cqf0nvpmj2v8.apps.googleusercontent.com
// google client secret dev - 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
