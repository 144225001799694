import React from "react";
import {
  CustomTitle,
  RegistrationTitleWrap,
  StyledCurveImage,
  StyleWaveIcon,
  SubtitleWrap,
  TitleWrap,
} from "./StyledComponent";
import PurchaseDetails from "./theme/registration/PurchaseDetails";

const dummyPurchaseDetails: any = {
  name: "subscription_test_name",
  email: "john.doe@test.com",
  quantity: "1",
  status: "Pending Activation",
  tenant_id: "3f2f6b3c-1bd9-11ee-be56-0242ac120002",
  plan_id: "basic_user_free_trial",
  is_free_trial: "True",
  auto_renewal: "False",
  billing_term: "1 Month",
};

function RegistrationTitle({
  titleColor,
  registrationData,
  bannerIcon,
  pageType,
  brandColor,
  cloud,
}: any) {
  return (
    <RegistrationTitleWrap>
      {!bannerIcon && (
        <TitleWrap gap='8'>
          <CustomTitle
            color={titleColor[0]}
            fontFamily='nunito-sans'
            fontWeight={800}
            lineheight={48}
            fontSize={40}
            className='registration-title'
          >
            {registrationData?.title || registrationData?.domain}
          </CustomTitle>
          <CustomTitle
            fontFamily='nunito-sans'
            fontWeight={400}
            fontSize={20}
            lineheight={28}
            color={titleColor[0]}
            className='registration-tagline'
          >
            {registrationData?.tagline || "Your company tagline"}
          </CustomTitle>
        </TitleWrap>
      )}
      {!bannerIcon && cloud !== "azure" && (
        <SubtitleWrap gap='16' pageType={pageType}>
          <CustomTitle
            fontFamily='nunito-sans'
            fontWeight={400}
            fontSize={22}
            lineheight={30}
            color={titleColor[1]}
            className='registration-subtitle'
          >
            One more step...
          </CustomTitle>
          <CustomTitle
            fontFamily='nunito-sans'
            fontWeight={400}
            fontSize={20}
            lineheight={28}
            color={titleColor[1]}
            className='registration-subtitle-tagline'
          >
            Thank you for your purchase. Please provide the required details to activate your
            account.
          </CustomTitle>
        </SubtitleWrap>
      )}
      {cloud === "azure" ? (
        <PurchaseDetails
          subscriptionDetails={registrationData?.subscription_details || dummyPurchaseDetails}
          cloud={cloud}
        />
      ) : null}
      {/* {cloud === "gcp" ? (
        <PurchaseDetails
          subscriptionDetails={registrationData?.gcp_user_details || dummyPurchaseDetails}
          cloud={cloud}
        />
      ) : null} */}
      {pageType === "classic" && (
        <StyleWaveIcon>
          <StyledCurveImage
            className='mobile-curve-img'
            preview={false}
            brandColor={brandColor}
            src='/images/mobile-curve-img.svg'
          />
        </StyleWaveIcon>
      )}
    </RegistrationTitleWrap>
  );
}

export default RegistrationTitle;
