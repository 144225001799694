import React from "react";
import Registration from "./registration/Registration";

function RegistrationTheme({ registrationData, successMessage, onFinish, isLoading, cloud }: any) {
  return (
    <Registration
      registrationData={registrationData}
      pageType={registrationData.theme}
      logo={registrationData?.logo}
      domain={registrationData?.domain}
      brandColor={registrationData?.background_color}
      buttonColor={registrationData?.button_color}
      successMessage={successMessage}
      onFinish={onFinish}
      isLoading={isLoading}
      cloud={cloud}
      hideClazarBranding={registrationData?.clazar_branding_disabled}
    />
  );
}

export default RegistrationTheme;
