import { combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
// import {
//   authReducer,
//   signupReducer,
//   resetPasswordReducer,
//   settingsReducer,
//   listingsReducer,
// } from "./pages";

const createRootReducer = (injectedReducers = {}) =>
  combineReducers({
    ...injectedReducers,
    // authState: authReducer,
    // signupState: signupReducer,
    // resetPasswordState: resetPasswordReducer,
    // settingsState: settingsReducer,
    // listingsState: listingsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  });

export { createRootReducer };
