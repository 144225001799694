export type CustomTitlePropsType = {
  mt?: number;
  mb?: number;
  fontWeight?: number;
  fontSize?: number;
  lineheight?: number;
  color?: string;
  fontFamily?: string;
};

export type GapPropsType = {
  gap?: string;
  background?: string;
  pageType?: string;
};

export type FormWrapPropsType = {
  padding?: string;
  gap?: string;
  labelFontSize?: string;
  labelLineHeight?: string;
};

export type CommonThemeWrapPropsType = {
  linearGradient?: string;
  pageType?: string;
  brandColor?: string;
  successMessage?: boolean;
};

export type SuccessModalPropsType = {
  isModalOpen?: boolean;
  registrationSuccessMessage?: string;
  buttonTitle?: string;
  btnURL?: string;
  preview?: string;
  btnColor?: string;
  titleColor?: any;
  title?: string;
};

export const SuccessModalDefaultProps = {
  isModalOpen: false,
  registrationSuccessMessage:
    "Thank you for providing the details, we’ll be in touch with you soon.",
  buttonTitle: "Completed",
  btnURL: "",
  btnColor: "",
  titleColor: ["#fff", "#000"],
};
