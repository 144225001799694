import React, { useState } from "react";
import { Spin } from "antd";
import {
  useUpdateRegisterationDetailsMutation,
  useGetRegistrationDetailsQuery,
} from "../redux/slices/register/registerApiSlice";
import RegistrationTheme from "../theme/RegistrationTheme";
import NotFound from "../not-found";
import { Section } from "../StyledComponent";

function CloudRegistration({ token, cloud }: { token: string; cloud: string }) {
  const [successMessage, setSuccessMessage] = useState(false);

  const { data, isLoading, isError } = useGetRegistrationDetailsQuery(
    { token, cloud },
    {
      skip: !token || !cloud,
    },
  );

  const [updateCloudRegisteration, { isError: hasError, isLoading: loading }] =
    useUpdateRegisterationDetailsMutation();

  const onFinish = async (values: any) => {
    const registrationData = (data?.registration_page_fields || [])?.map((item: any) => ({
      registration_page_field: item?.id,
      value: values?.[item?.title] || "",
    }));

    await updateCloudRegisteration({
      data: registrationData,
      cloud,
      token,
    });
    setSuccessMessage(true);
  };

  if (token && !hasError && !isError) {
    return (
      <Spin spinning={isLoading}>
        <Section style={{ height: "100vh" }}>
          {!isLoading && (
            <RegistrationTheme
              registrationData={data}
              successMessage={successMessage}
              onFinish={onFinish}
              isLoading={loading}
              cloud={cloud}
            />
          )}
        </Section>
      </Spin>
    );
  }

  return <NotFound />;
}

export default CloudRegistration;
