import React from "react";
import {
  CustomTitle,
  PurchaseDetailsWrap,
  ListItem,
  ListsWrap,
  Section,
} from "../../StyledComponent";

const purchaseFields: any = {
  azure: {
    name: "Subscription Name",
    plan_id: "Plan ID",
    quantity: "No. of Users",
    status: "Subscription Status",
    email: "Email ID",
    tenant_id: "Tenant ID",
    auto_renewal: "Auto Renew",
    is_free_trial: "Is Free Trial",
    billing_term: "Duration",
  },
  gcp: {
    name: "Subscription Name",
    email: "Email ID",
  },
};

const getPurchaseValue = (value: string | boolean) => {
  if (typeof value === "boolean") {
    return value ? "True" : "False";
  }
  return value;
};

function PurchaseDetails({
  subscriptionDetails = {},
  cloud,
}: {
  subscriptionDetails: any;
  cloud: "azure" | "gcp";
}) {
  const keys = Object.keys(purchaseFields[cloud]);
  return (
    <PurchaseDetailsWrap>
      <Section style={{ marginBottom: "16px" }}>
        <CustomTitle fontWeight={800} fontSize={20} lineheight={28} color='#0D1A5B' mb={16}>
          Your Purchase Details
        </CustomTitle>
      </Section>
      {keys?.map((key: string) =>
        subscriptionDetails[key] === false || subscriptionDetails[key] ? (
          <ListsWrap key={purchaseFields[cloud][key]}>
            <ListItem>{purchaseFields[cloud][key]}:</ListItem>
            <ListItem>{getPurchaseValue(subscriptionDetails?.[key])}</ListItem>
          </ListsWrap>
        ) : null,
      )}
    </PurchaseDetailsWrap>
  );
}

export default PurchaseDetails;
