import React from "react";
import tinycolor from "tinycolor2";
import RegistrationModal from "../../RegistrationModal";
import {
  CommonThemeWrap,
  RegistrationModalWrap,
  Section,
  StyledCurveImage,
  StyledWave,
  StyleWaveIcon,
} from "../../StyledComponent";
import ContentSection from "./ContentSection";
import RegistrationFooter from "./RegistrationFooter";
import RegistrationHearder from "./RegistrationHearder";

function Registration({
  registrationData,
  pageType,
  logo,
  domain,
  brandColor,
  buttonColor,
  successMessage,
  onFinish,
  isLoading,
  cloud,
  hideClazarBranding,
}: any) {
  const getColor = () => {
    if (pageType === "clean") return ["#fff", "#000"];
    const isLight = tinycolor(brandColor).isLight();
    if (pageType === "classic") {
      return [isLight ? "#212529" : "#fff", "#000"];
    }
    const color = isLight ? "#212529" : "#fff";
    return [color, color];
  };
  return (
    <Section>
      <CommonThemeWrap
        brandColor={brandColor}
        linearGradient={pageType === "bold" ? "90deg" : "180deg"}
        pageType={pageType}
        successMessage={successMessage}
      >
        <RegistrationHearder logo={logo} hideClazarBranding={hideClazarBranding} />
        {!successMessage ? (
          <ContentSection
            buttonColor={buttonColor}
            pageType={pageType}
            brandColor={brandColor}
            registrationData={registrationData}
            onFinish={onFinish}
            titleColor={getColor()}
            isLoading={isLoading}
            cloud={cloud}
          />
        ) : (
          <RegistrationModalWrap className={`${pageType === "classic" && "classic-modal"}`}>
            <RegistrationModal
              title={registrationData?.title}
              registrationSuccessMessage={registrationData?.success_message}
              btnURL={registrationData?.success_button_url}
              preview='success'
              buttonTitle={
                registrationData.success_button_title ||
                `Go to ${registrationData?.title || domain}`
              }
              btnColor={buttonColor}
            />
            {pageType === "classic" && (
              <StyleWaveIcon>
                <StyledCurveImage
                  className='mobile-curve-img classic-modal-curve'
                  preview={false}
                  brandColor={brandColor}
                  src='/images/mobile-curve-img.svg'
                />
              </StyleWaveIcon>
            )}
          </RegistrationModalWrap>
        )}
        {/* {pageType === "classic" && (
      <StyleWaveIcon>
        <StyledCurveImage
          className='curve-image'
          preview={false}
          brandColor={brandColor}
          src='/images/curve.svg'
        />
      </StyleWaveIcon>
    )} */}
      </CommonThemeWrap>
      {pageType === "classic" && <StyledWave brandColor={brandColor} />}
      {/* <div className='wave' /> */}
      <RegistrationFooter
        pageType={pageType}
        domain={domain}
        brandColor={brandColor}
        hideClazarBranding={registrationData?.clazar_branding_disabled}
      />
    </Section>
  );
}

export default Registration;
