import React from "react";
import { Image } from "antd";
import { StyledErrorPage } from "./ErrorStyledComponent";
import { CustomTitle, FlexWrap } from "../StyledComponent";

function Index() {
  return (
    <StyledErrorPage>
      <Image preview={false} src='/images/error.svg' />
      <FlexWrap flexDir='column'>
        <CustomTitle fontSize={24} lineheight={32} color='#212529'>
          Something went wrong...
        </CustomTitle>
      </FlexWrap>
    </StyledErrorPage>
  );
}

export default Index;
