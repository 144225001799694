import { createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "../constants";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem("token");
    // if (accessToken) {
    //   headers.set("Authorization", `Bearer ${accessToken}`);
    // } else {
    //   headers.delete("Authorization");
    // }
    return headers;
  },
});

const baseQueryWithReauth = async (args: string | FetchArgs, api: any, extraOptions: any) => {
  const result: any = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    // api.dispatch(logout());
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["AWSRegistrationDetails", "RegistrationDetails"],
  endpoints: () => ({}),
});
