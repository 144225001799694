import React from "react";
import { Row, Col, ConfigProvider } from "antd";
import RegistrationTitle from "../../RegistrationTitle";
import RegistrationForm from "../../RegistrationForm";
import { RegistrationFormWrap } from "../../StyledComponent";

function ContentSection({
  pageType,
  brandColor,
  registrationData,
  buttonColor,
  onFinish,
  titleColor,
  isLoading,
  cloud,
}: any) {
  return (
    <ConfigProvider
      theme={{
        token: {
          screenMD: 913,
          screenMDMin: 913,
          screenSMMax: 912,
          screenSM: 912,
        },
      }}
    >
      <Row className='content-row'>
        <Col md={12} sm={24} xs={24}>
          <RegistrationTitle
            titleColor={titleColor}
            registrationData={registrationData}
            pageType={pageType}
            brandColor={brandColor}
            cloud={cloud}
          />
        </Col>
        <Col
          md={12}
          sm={24}
          xs={24}
          className={`column-registration registration-form ${
            pageType === "clean" && "reg-form-clean"
          } ${pageType === "bold" && "reg-form-bold"} ${
            pageType === "classic" && "reg-form-classic"
          }`}
        >
          <RegistrationFormWrap
            className={`${pageType === "bold" && "theme-bold"} 
          ${pageType === "clean" && "theme-clean"} ${pageType === "classic" && "theme-classic"}`}
          >
            <RegistrationForm
              buttonColor={buttonColor}
              pageType={pageType}
              // preview={preview}
              // handleSuccessMessage={handleSuccessMessage}
              registrationData={registrationData}
              onFinish={onFinish}
              isLoading={isLoading}
            />
          </RegistrationFormWrap>
        </Col>
      </Row>
    </ConfigProvider>
  );
}

export default ContentSection;
