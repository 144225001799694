import { apiSlice } from "../../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    themeDetails: builder.query({
      query: ({ uuid }: { uuid: string }) => `/registration/${uuid}/`,
    }),
    getAWSRegistraionDetails: builder.query({
      query: ({
        sellerId,
        productCode,
        customerIdentifier,
      }: {
        sellerId: string | undefined;
        productCode: string | undefined;
        customerIdentifier: string | undefined;
      }) => `/register/${sellerId}/${productCode}/${customerIdentifier}/`,
      providesTags: ["AWSRegistrationDetails"],
    }),
    getRegistrationDetails: builder.query({
      query: ({ token, cloud }) => `/register/?token=${token}&cloud=${cloud}`,
      providesTags: ["RegistrationDetails"],
    }),
    createAWSRegisteration: builder.mutation({
      query: ({
        data,
        sellerId,
        productCode,
        customerIdentifier,
      }: {
        data: any;
        sellerId: string | undefined;
        productCode: string | undefined;
        customerIdentifier: string | undefined;
      }) => ({
        url: `/register/${sellerId}/${productCode}/${customerIdentifier}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["AWSRegistrationDetails"],
    }),
    updateRegisterationDetails: builder.mutation({
      query: ({ data, token, cloud }: { data: any; token: string; cloud: string }) => ({
        url: `/register/?token=${token}&cloud=${cloud}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["RegistrationDetails"],
    }),
  }),
});

export const {
  useThemeDetailsQuery,
  useGetRegistrationDetailsQuery,
  useGetAWSRegistraionDetailsQuery,
  useCreateAWSRegisterationMutation,
  useUpdateRegisterationDetailsMutation,
} = authApiSlice;
