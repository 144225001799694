import React, { useState } from "react";
import { Spin } from "antd";
import {
  useCreateAWSRegisterationMutation,
  useGetAWSRegistraionDetailsQuery,
} from "../redux/slices/register/registerApiSlice";
import RegistrationTheme from "../theme/RegistrationTheme";
import NotFound from "../not-found";
import { Section } from "../StyledComponent";

function AWSRegistartion({
  sellerId,
  productCode,
  customerIdentifier,
}: {
  sellerId: string;
  productCode: string;
  customerIdentifier: string;
}) {
  const [successMessage, setSuccessMessage] = useState(false);

  const { data, isLoading, isError } = useGetAWSRegistraionDetailsQuery(
    {
      sellerId,
      productCode,
      customerIdentifier,
    },
    {
      skip: !(sellerId && productCode && customerIdentifier),
    },
  );

  const [createRegisterListMutation, { isError: hasError, isLoading: loading }] =
    useCreateAWSRegisterationMutation();

  const onFinish = async (values: any) => {
    const registrationData = (data?.registration_page_fields || [])?.map((item: any) => ({
      registration_page_field: item?.id,
      value: values?.[item?.title] || "",
    }));

    await createRegisterListMutation({
      data: registrationData,
      sellerId,
      productCode,
      customerIdentifier,
    });
    setSuccessMessage(true);
  };

  if (sellerId && productCode && customerIdentifier && !hasError && !isError) {
    return (
      <Spin spinning={isLoading}>
        <Section style={{ height: "100vh" }}>
          {!isLoading && (
            <RegistrationTheme
              registrationData={data}
              successMessage={successMessage}
              onFinish={onFinish}
              isLoading={loading}
            />
          )}
        </Section>
      </Spin>
    );
  }

  return <NotFound />;
}

export default AWSRegistartion;
