import React from "react";
import styled from "@emotion/styled";
import { Section } from "../StyledComponent";

export const StyledErrorPage = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 48px;
  margin-top: 248px;
`;
